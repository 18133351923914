<template>
  <div class="mainContainer flex flex-col items-center text-center px-5 pb-10 lg:px-0">
      <ul
      v-for="global in globals"
      :key="global.globalDiscountType"
    >
      <div
        v-if="global.globalDiscountType === 'amount'"
      >
        <ul
          v-for="global in globals"
          :key="global.globalDiscountAmount"
        >
          <div class="mt-10">
            <router-link
              :to="{ name: 'home' }"
              style="max-width: 220px"
              class="flex flex-row mb-5 mx-auto p-2 text-white uppercase tracking-wide bg-secondary rounded centerContainer"
            >
              <BackButtonIcon class="mr-1" />
              Back To Price List
            </router-link>

            <h2 class="font-bold text-3xl">Buyer Profile</h2>
            <p class="mt-2">
              Complete your buyer profile to unlock your {{global.globalDiscountAmount | prettyCurrency}} launch discount.
            </p>
          </div>
        </ul>
      </div>
      <div
        v-if="global.globalDiscountType === 'percentage'"
      >
        <ul
          v-for="global in globals"
          :key="global.globalDiscountAmount"
        >
          <div class="mt-10">
            <router-link
              :to="{ name: 'home' }"
              style="max-width: 220px"
              class="flex flex-row mb-5 mx-auto p-2 text-white uppercase tracking-wide bg-secondary rounded"
            >
              <BackButtonIcon class="mr-1" />
              Back To Price List
            </router-link>

            <h2 class="font-bold text-3xl">Buyer Profile</h2>
            <p class="mt-2">
              Complete your buyer profile to unlock your {{global.globalDiscountAmount}}% launch discount.
            </p>
          </div>
        </ul>
      </div>

      <form
        @submit.prevent="submit"
        @keyup.enter.prevent="submit"
        class="survey-container max-w-5xl mt-10 bg-gray-200 rounded p-5"
      >
        <div
          class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2"
        >
          <label class="lb-text-label">City of residence</label>
          <input
            @click="dropdown = false , dropdownContact = false"
            v-model="form.city"
            v-on:input="removeCharacters"
            class="w-full border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
            type="text"
          />
          <span
            class="text-xs text-red-600"
            :class="{ '': $v.form.city.$error, invisible: !$v.form.city.$error}"
          >
            Required
          </span>
        </div>


        <div
          class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2"
        >
          <label class="lb-text-label">Age</label>
          <input
            @click="dropdown = false , dropdownContact = false"
            v-model="form.age"
            v-on:input="removeCharacters"
            class="w-full border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
            type="number"
          />
          <span
            class="text-xs text-red-600"
            :class="{ '': $v.form.age.$error, invisible: !$v.form.age.$error }"
          >
            Required
          </span>
        </div>

        <div
          class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2"
        >
          <label class="lb-text-label">Household Income</label>
          <select
            @click="dropdown = false , dropdownContact = false"
            v-model="form.houseHoldIncome"
            v-on:input="removeCharacters"
            class="w-full border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
          >
            <option value="'R 0 - R20 000">R 0 - R20 000</option>
            <option value="R20 000 - R30 000">R20 000 - R30 000</option>
            <option value="R30 000 - R45 000">R30 000 - R45 000</option>
            <option value="R45 000 - R60 000">R45 000 - R60 000</option>
            <option value="R60 000 - R80 000">R60 000 - R80 000</option>
            <option value="R80 000 - R100 000">R80 000 - R100 000</option>
            <option value="R100 000+">R100 000+</option>
            <option value="Rather not say">Rather not say</option>
          </select>
          <span
            class="text-xs text-red-600"
            :class="{
            '': $v.form.houseHoldIncome.$error,
            invisible: !$v.form.houseHoldIncome.$error,
          }"
          >
            Required
          </span>
        </div>

        <div
          class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2"
        >
          <label class="lb-text-label">Job Title</label>
          <input
            @click="dropdown = false , dropdownContact = false"
            v-model="form.jobTitle"
            v-on:input="removeCharacters"
            class="w-full border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
            type="text"
          />
          <span
            class="text-xs text-red-600"
            :class="{
            '': $v.form.jobTitle.$error,
            invisible: !$v.form.jobTitle.$error,
          }"
          >
            Required
          </span>
        </div>
        <div
          class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2"
        >
          <label class="lb-text-label">Reason for buying</label
          >
          <select
            @click="dropdown = false , dropdownContact = false"
            v-model="form.reasonBuying"
            v-on:input="removeCharacters"
            class="w-full border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
          >
            <option value="investor">Investor</option>
            <option value="ownUse">Own use</option>
            <option value="parent">Parent</option>
            <option value="parentAndInvestor">Parent & Investor</option>
          </select>
          <span
            class="text-xs text-red-600"
            :class="{
            '': $v.form.reasonBuying.$error,
            invisible: !$v.form.reasonBuying.$error,
          }"
          >
          Required
        </span>
        </div>
           <!--dropdown CONTACT-->
        <div class=" flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2 "  >
          <label class="lb-text-label">
            Preferred contact method
          </label>
          <div class="dropdownBoxStylingContact" :class="dropdownContact?' bordering':'active'">
          <div @click="setDropdownContact" class="flex"
          :class="dropdownContact?' borderingThin':'active'">
            <div class="dropdownStyling">{{form.contactMethods.length}} selected</div>
            <div class="dropdownStylingArrow" ><i class="arrow down"></i></div>
          </div>
          <ul           
              v-if="dropdownContact"
              v-on-clickaway="toggleDropdownContact"
              :class="dropdownContact?' borderingGreyCheckboxesDropdown':'active'"
            class="font-normal rounded dropdownItemsBox tracking-wider cursor-pointer absolute checkboxesCSS">
              <li
              v-for="contact in contactOptions"
              :key="contact"
              class="mb-2">
                <label class="inline-flex items-center">
                <input
                v-model="form.contactMethods"
                :value="contact"
                type="checkbox"
                class="rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                >
                <span class="ml-2">{{contact}}</span>
                </label>
              </li>
          </ul>
        </div>
            <span
            class="text-xs text-red-600"
            :class="{ '': $v.form.contactMethods.$error, invisible: !$v.form.contactMethods.$error}"
          >
            Required
          </span>
      </div>
     
      <!-- redo-language Hein-->
        <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2" >
          <label class="lb-text-label">
            Preferred language
          </label>
          <div class="inputStyling">
               <input
            v-model="form.language"
            v-on:input="removeCharacters"
            @click="dropdown = false , dropdownContact = false"
            class="w-full border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
            type="text"
          />
          <span
            class="text-xs text-red-600"
            :class="{ '': $v.form.language.$error, invisible: !$v.form.language.$error}"
          >
            Required
          </span>
        </div>
      </div>

         <!--Submit-->
        <div
          class="w-full mt-5 ml-2 text-left btn-container"
        >
        <button 
          v-bind="{isLoading, status}"
          class="px-5 py-2 bg-primary font-semibold uppercase rounded"
          type="submit">
            <ButtonSpinner
              class="px-5 py-2 bg-primary font-semibold uppercase rounded"
              style="background-color:#AA9461;
                      color:black;
                      font-size:18px;
                      border:none;"
                @click.native="onClick"
                @click.prevent="submit"
                v-bind="{isLoading, status}"
                :disabled="isLoading"
                type="submit"
              >
                Save &amp; Unlock Discount
            </ButtonSpinner>
        </button>
        </div>

        <div
          class="w-full h-full"
          v-if="global.globalDiscountType === 'amount'"
        >
          <ul
            v-for="global in globals"
            :key="global.globalDiscountAmount"
          >
            <span
              v-if="profileCompleted"
              class="unlocked-screen rounded bg-gray-200"
            >
              <div class="unlocked-screen-content">
                <UnlockIcon :size="50" class="text-green-500" />
                <p class="mt-5 text-lg font-semibold">
                  Your {{global.globalDiscountAmount | prettyCurrency}} discount has been unlocked!
                </p>
                <router-link
                  :to="{ name: 'home' }"
                  class="mt-2 lb-primary-button"
                >
                  GO TO PRICELIST
                </router-link>
              </div>
            </span>
          </ul>
        </div>
        <div
          class="w-full h-full"
          v-if="global.globalDiscountType === 'percentage'"
        >
          <ul
            v-for="global in globals"
            :key="global.globalDiscountAmount"
          >
            <span
              v-if="profileCompleted"
              class="unlocked-screen rounded bg-gray-200"
            >
              <div class="unlocked-screen-content">
                <UnlockIcon :size="50" class="text-green-500" />
                <p class="mt-5 text-lg font-semibold">
                  Your {{global.globalDiscountAmount}}% discount has been unlocked!
                </p>
                <router-link
                  :to="{ name: 'home' }"
                  class="mt-2 lb-primary-button"
                >
                  GO TO PRICELIST
                </router-link>
              </div>
            </span>
          </ul>
        </div>
      </form>
    </ul>
  </div>
</template>

<script>
import ButtonSpinner from 'vue-button-spinner';
import { mixin as clickaway } from 'vue-clickaway';
import firebase from "firebase";
import { mapGetters } from "vuex";
import {profilesCollection, settingsCollection} from "@/firebase";

import UnlockIcon from "vue-material-design-icons/LockOpenVariant.vue";
import BackButtonIcon from "vue-material-design-icons/ArrowLeftCircleOutline";

import { required } from "vuelidate/lib/validators";
import {APP_TITLE} from "@/Constants";

//import ChecklistInput from '@/components/app/SurveyChecklistInput.vue'

export default {
  name: "Survey",
  components: {
    ButtonSpinner,
    BackButtonIcon,
    UnlockIcon,
    //ChecklistInput
  },

  data: () => ({
    isLoading: false,
    status: '',
    title: APP_TITLE,
    globals: [],
    loading: false,
    profileCompleted: false,
    form: {
      surveyForm: "Buyer Profile",
      city: "",
      age: "",
      houseHoldIncome: null,
      company: "",
      jobTitle: "",
      reasonBuying: "",
      isMarketBuy: "",
      whyInterested: [],
      created: null,
      modified: null,
      language: "",
      contactMethods: [],
    },
    dropdown: false,
    dropdownContact: false,
    displayDropdown: false,
    contactOptions: [
      "Whatsapp",
      "Phone",
      "Email",
      "Please don't contact me"
    ],
    attractions: [
      "Amenities",
      "Proximity to Stellenbosch University",
      "Great investment opportunity",
      "Guaranteed Rental",
      "Safety & Security",
    ],
  }),
  mixins: [ clickaway ],
  validations: {
    form: {
      city: { required },
      age: { required },
      houseHoldIncome: { required },
      //company: { required },
      jobTitle: { required },
      reasonBuying: { required },
      // isMarketBuy: { required },
      //whyInterested: { required },
      contactMethods: {required},
      language: {required},
    },
  },
  methods: {
     onClick () {
			this.isLoading = true
			setTimeout(() => {
				this.isLoading = false
				this.clear()
			}, 8000)
		},
		clear () {
			setTimeout(() => {
			}, 8000)
		},
     setDropdown(){
      this.dropdownContact = false
      this.dropdown = !this.dropdown
      console.log('dropdownStellenbosch set to ' + this.dropdown)
    },
    setDropdownContact(){
      this.dropdown = false
      this.dropdownContact = !this.dropdownContact
      console.log('dropdown Contact set to ' + this.dropdownContact)
    },
    toggleDropdownContact() {
      this.dropdownContact = !this.dropdownContact;
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    removeCharacters: function() {
      this.form.city = this.form.city.replace(/,/g, ' ');
      this.form.age = this.form.age.replace(/,/g, ' ');
      this.form.houseHoldIncome = this.form.houseHoldIncome.replace(/,/g, ' ');
      this.form.company = this.form.company.replace(/,/g, ' ');
      this.form.jobTitle = this.form.jobTitle.replace(/,/g, ' ');
      this.form.reasonBuying = this.form.reasonBuying.replace(/,/g, ' ');
      this.form.isMarketBuy = this.form.isMarketBuy.replace(/,/g, ' ');
      this.form.whyInterested = this.form.whyInterested.replace(/,/g, ' ');
      this.form.contactMethods = this.form.contactMethods.replace(/,/g, ' ');
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.loading = false;
        return
      }

      const timeStamp = firebase.firestore.Timestamp.now();

      const formData = this.form;

      const profileUpdateData = {
        answeredDiscountSurvey: true,

        discountSurveyTimestamp: timeStamp,
        discountSurveyAge: formData.age,
        discountSurveyCity: formData.city,
        //discountSurveyCompany: formData.company,
        discountSurveyHouseHoldIncome: formData.houseHoldIncome,
        discountSurveyJobTitle: formData.jobTitle,
        discountSurveyReasonBuying: formData.reasonBuying,
        //discountSurveyIsMarketBuy: formData.isMarketBuy,
        discountSurveyWhyInterested: formData.whyInterested,
        discountSurveyContactMethods: formData.contactMethods,
        discountSurveyLanguage: formData.language,

      };

      const LMSUpdateData = {
        email: this.user.profile.email,
        firstName: this.user.profile.firstName,
        lastName: this.user.profile.lastName,
        e164ContactNumber: this.user.profile.e164ContactNumber,

        discountSurveyAge: formData.age,
        discountSurveyCity: formData.city,
        //discountSurveyCompany: formData.company,
        discountSurveyHouseHoldIncome: formData.houseHoldIncome,
        discountSurveyJobTitle: formData.jobTitle,
        discountSurveyReasonBuying: formData.reasonBuying,
        //discountSurveyIsMarketBuy: formData.isMarketBuy,
        discountSurveyWhyInterested: formData.whyInterested,
        discountSurveyContactMethods: formData.contactMethods,
        discountSurveyLanguage: formData.language,

      };

      profilesCollection
        .doc(this.user.data.uid)
        .update(profileUpdateData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            text: "You have completed the survey",
          });

          this.profileCompleted = true;

          return LMSUpdateData;
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            text: error.message,
          });
        });
    },
  },
  created() {
    settingsCollection.orderBy("globalDiscountAmount").onSnapshot((snap) => {
      let globals = [];
    

      snap.forEach((doc) => {
        globals.push({
          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          displaySplashScreen: doc.data().displaySplashScreen,
          splashScreenMessage: doc.data().splashScreenMessage,
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
        });
      });
      this.globals = globals;
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style>
.centerContainer {
  justify-content: center;
  align-items: center;
}

.survey-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.survey-content {
  margin: 0 25%;
}

.btn-content {
  text-align: left;
}

.unlocked-screen {
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

}

.btn-container {
  justify-content: center;
  display: flex;
}
.checkboxesCSS {
  width: 100%;
  display: inline-block;
  /*align-content: left;*/
  background-color: white;
   padding-left: .8rem;
   padding-top:10px;
}
.dropdownStyling {
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: .25rem;
  padding-left: 15px;
  padding-top: 7px;
}
.dropdownStylingArrow {
  width: 40px;
  height: 40px;
 background-color: white;
  border-radius: .25rem;
  text-align: center;
  padding-top: 4px;
  padding-right: 5px;
}
.itemSpacing {
  padding-top: 5px;
}
.inputStyling {
 text-align: left;
 width: 100%;
 border-radius: .25rem;
}
.dropdownBoxStyling {
   background-color: white;

 border: 1px solid grey; 
 width: 100%;
 text-align: left;
 border-radius: .25rem;
 position: relative;
 z-index: 9;
}
.SpinnerComponent{
  z-index: 7 !important;
}
.dropdownItemsBox{
      z-index: 2000 !important;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.dropdownBoxStylingContact {
   background-color: white;
 border: 1px solid grey; 
 width: 100%;
 text-align: left;
 border-radius: .25rem;
 position: relative;
 z-index: 8;
}
.dropdownBoxStyling:active {
  border: 2px #aa9461 solid;
}
.arrow {
  border: solid grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.bordering {
  border: 2px #aa9461 solid;
}
.borderingThin {
  border-bottom: 1px #aa9461 solid;
}
.borderingNoTop {
  border-left: 2px #aa9461 solid;
    border-right: 2px #aa9461 solid;
  border-bottom: 2px #aa9461 solid;
  border-top: 2px #aa9461 solid;
}
.outerBox { 
  background-color: white;
}
.mainContainer {  
  min-height: 85vh;
  margin: auto;
}
 /* .borderingGreyCheckboxesDropdown {
  border: solid grey 1px;
}  */
.unlocked-screen-content {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  z-index: 10;
}
@media only screen and (max-width: 425px) {
  .btn-content {
    text-align: left;
    margin: 0 25%;
  }
}

</style>
